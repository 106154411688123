import "@/styles/index.scss";
import "./utils/scroll";

class App {

}

new App();

console.log("%c Developed by morten.studio - https://morten.studio/", "background: #000; color: #fff;");
